import { cloneElement } from 'react'

import s from './FooterBottom.module.scss'

type ListProps = {
  children?: React.ReactNode
}

export const FooterBottom = ({ children }: ListProps) => {
  if (!children) {
    return null
  }

  return <ul className={s.footerBottom}>{children}</ul>
}

export const FooterBottomItem = ({
  children,
}: {
  children?: React.ReactElement
}) => {
  if (!children) {
    return null
  }
  return (
    <li className={s.footerBottom__item}>
      {cloneElement(children, { className: s.footerBottom__content })}
    </li>
  )
}

export const FooterBottomCertifications = ({ children }: ListProps) => {
  if (!children) {
    return null
  }

  return <ul className={s.footerBottomCertifications}>{children}</ul>
}

export const FooterBottomContainer = ({ children }: ListProps) => {
  if (!children) {
    return null
  }

  return <div className={s.footerBottomContainer}>{children}</div>
}
