import classnames from 'classnames/bind'

import { Link } from 'components/link/Link'
import { ButtonIconList, Svg } from 'components/svg/Svg'

import s from './Button.module.scss'

const c = classnames.bind(s)

type ButtonDefaults = {
  disabled?: boolean
  children: React.ReactNode
  className?: string
  transition?: boolean
  icon?: ButtonIconList
  iconRight?: boolean
}

type ButtonLinkProps = {
  to?: string
  onClick?: never
  transition?: boolean
} & ButtonDefaults &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

type ButtonButtonProps = {
  to?: never
  onClick?: () => void
  transition?: never
} & ButtonDefaults &
  React.ButtonHTMLAttributes<HTMLButtonElement>

// either a link or a button but dont mix!
export type ButtonProps = ButtonButtonProps | ButtonLinkProps

export const Button = ({
  to,
  children,
  className,
  disabled,
  transition,
  icon,
  iconRight = true,
  ...props
}: ButtonProps) => {
  const classNames = c(className, s.button, {
    disabled,
    iconRight,
    iconLeft: !iconRight,
  })

  const Icon = () =>
    icon ? (
      <span className={s.button__iconWrap}>
        <Svg className={s.button__icon} id={icon} />
      </span>
    ) : null

  if (to) {
    return (
      <Link
        to={to}
        transition={transition}
        className={classNames}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        <Icon />
        {children}
      </Link>
    )
  }

  return (
    <button
      disabled={disabled}
      aria-disabled={disabled}
      className={classNames}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      <Icon />
      {children}
    </button>
  )
}
