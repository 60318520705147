import { UIProvider } from 'context/ui'
import { AppProps as NextAppProps } from 'next/app'
import { PrismicLink } from 'prismic/types/link'
import {
  Maybe,
  Navigation,
  Layout as PrismicLayout,
  Page as PrismicPage,
} from 'prismic-types'

import { Devtools } from 'components/devtools/Devtools'
import { Layout } from 'components/layout/Layout'
import { Meta } from 'containers/meta/Meta'

import 'styles/global.scss'
import { Banner } from 'components/banner/Banner'
import { useEffect, useRef, useState } from 'react'
import {
  clearStoredBannerData,
  getStoredBannerData,
  setStoredBannerData,
} from 'utils/bannerSessionStoarage'

type InheritedPageProps = {
  layout?: PrismicLayout | null
  page?: PrismicPage | null
  preview?: boolean
  onboardingButtonLink?: PrismicLink
  navigation: (Maybe<Navigation> | undefined)[]
}

type AppProps<P> = {
  pageProps: P
} & Omit<NextAppProps<P>, 'pageProps'>

export default function App({
  Component,
  pageProps,
}: AppProps<InheritedPageProps>) {
  const isDev = process.env.NODE_ENV === 'development'
  const layout = pageProps.layout as PrismicLayout | null
  const navigation = pageProps.navigation

  // Check if SideButton should be shown on current page
  const onboardingLink = pageProps.onboardingButtonLink ?? undefined

  // Banner
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const [bannerHeight, setBannerHeight] = useState<number>(0)
  const bannerRef = useRef<HTMLDivElement | null>(null)

  const bannerData = {
    showBanner: layout?.show_banner,
    bannerType: layout?.banner_type,
    bannerTitle: layout?.banner_title,
    bannerDescription: layout?.banner_description
      ? JSON.stringify(layout?.banner_description)
      : undefined,
  }

  useEffect(() => {
    if (!bannerData.showBanner) {
      clearStoredBannerData()
      setShowBanner(false)
      return
    }

    const storedBannerData = getStoredBannerData()
    const isBannerDismissed = storedBannerData.dismissed === true
    const isBannerChanged =
      storedBannerData.showBanner !== bannerData.showBanner ||
      storedBannerData.bannerType !== bannerData.bannerType ||
      storedBannerData.bannerTitle !== bannerData.bannerTitle ||
      storedBannerData.bannerDescription !== bannerData.bannerDescription

    // Show banner if it's not dismissed or has changed
    if (!isBannerDismissed || isBannerChanged) {
      setShowBanner(true)
      setStoredBannerData({ ...bannerData, dismissed: false })
    }
  }, [bannerData])

  const handleBannerClose = () => {
    const storedBannerData = getStoredBannerData()
    setStoredBannerData({ ...storedBannerData, dismissed: true })
    setShowBanner(false)
  }

  useEffect(() => {
    // Adjust header position based on banner visibility
    const adjustHeaderPosition = () => {
      if (showBanner && bannerRef.current) {
        const bannerHeight = bannerRef.current.offsetHeight
        setBannerHeight(bannerHeight)
      } else {
        setBannerHeight(0)
      }
    }

    adjustHeaderPosition()

    window.addEventListener('resize', adjustHeaderPosition)

    return () => {
      window.removeEventListener('resize', adjustHeaderPosition)
    }
  }, [showBanner])

  return (
    <>
      <Meta />
      <UIProvider>
        {showBanner && (
          <Banner
            bannerRef={bannerRef}
            title={layout?.banner_title}
            description={layout?.banner_description}
            type={layout?.banner_type}
            onClick={handleBannerClose}
          />
        )}
        <Layout
          layout={layout}
          preview={pageProps.preview ?? false}
          onboardingButtonLink={onboardingLink}
          navigation={navigation}
          bannerHeight={bannerHeight}
        >
          <Component {...pageProps} />
        </Layout>
      </UIProvider>
      {isDev && <Devtools />}
    </>
  )
}
