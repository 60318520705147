import { MappedNavigation } from './Header'
import { linkResolver } from 'prismic/linkResolver'
import { Link } from 'components/link/Link'
import { motion, useAnimate } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { BaseMenuButton } from 'components/menu-button/MenuButton'
import { MegaMenuFeatured } from 'components/mega-menu/MegaMenuFeatured'
import { useUiState } from 'hooks/useUiState'
import { ArrowLeft } from 'components/icon/Icon'
import cx from 'classnames'

import s from './MobileNavbar.module.scss'

const MobileSublist = ({
  items,
  title,
  featured,
  activePage,
  handleClose,
}: Omit<
  MappedNavigation,
  'featured' | 'ordering' | 'childrenPaths' | 'uniquePaths'
> & {
  featured?: MappedNavigation['featured']
  handleClose: () => void
  activePage?: string
}) => {
  return (
    <div className={s.mobileSublist}>
      <button className={s.sectionTitle} onClick={() => handleClose()}>
        <div className={s.icon}>
          <ArrowLeft />
        </div>
        <span>{title}</span>
      </button>
      <div>
        <div className={s.groups}>
          {items.flat().map((item) => {
            return (
              <div className={s.group} key={item.groupLabel}>
                <div className={s.groupTitle}>{item.groupLabel}</div>
                <ul className={s.groupItems}>
                  {item.items.map((item) => {
                    const isActive = activePage === linkResolver(item.link)
                    return (
                      <li
                        key={item.title}
                        className={cx(s.groupItem, { [s.isActive]: isActive })}
                      >
                        {isActive && <div className={s.dot}></div>}
                        <Link to={linkResolver(item.link)}>{item.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
        {featured?.heading ? (
          <div className={s.featuredWrapper}>
            <MegaMenuFeatured featured={featured} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const useMenuAnimation = (isDrawerOpen: boolean) => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    const drawerAnimations = isDrawerOpen
      ? [
          [`.${s.mobileSublist}`, { opacity: 1 }, { duration: 0 }],
          [`.${s.mobileSublist}`, { transform: 'translateX(0%)' }, { at: '<' }],
        ]
      : [
          [`.${s.mobileSublist}`, { transform: 'translateX(100dvw' }],
          [`.${s.mobileSublist}`, { opacity: 0 }],
        ]

    animate([...drawerAnimations] as any)
  }, [isDrawerOpen, animate])

  return scope
}

export const MobileNavbar = ({ items }: { items: MappedNavigation[] }) => {
  const { uiState } = useUiState()
  const { currentPage } = uiState
  const currentPageIndex = useMemo(() => {
    return items.findIndex((i) => i.uniquePaths.includes(currentPage || ''))
  }, [items, currentPage])

  const [isDrawerOpen, setDrawerOpen] = useState(currentPageIndex >= 0)
  const [drawerIndex, setDrawerIndex] = useState(currentPageIndex)

  const scope = useMenuAnimation(isDrawerOpen)

  const handleClose = () => {
    setDrawerOpen(false)
    setDrawerIndex(-1)
  }

  useEffect(() => {
    // If the current page is a child of the current drawer, keep that drawer open
    if (
      drawerIndex >= 0 &&
      items[drawerIndex].childrenPaths.includes(currentPage || '')
    ) {
      return
    } else {
      // If the current page is not a child of the current drawer, find the new drawer if available
      setDrawerOpen(currentPageIndex >= 0)
      setDrawerIndex(currentPageIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex, currentPage])

  return (
    <motion.div
      className={s.mobileNavbar}
      ref={scope}
      variants={{
        closed: { transform: 'translateX(100dvw)' },
        open: { transform: 'translateX(0dvw)' },
      }}
      initial="closed"
      animate={uiState.isNavOpen ? 'open' : 'closed'}
    >
      <ul className={s.mobileNavList}>
        {items.map((item, i) => {
          return (
            <li className={s.item} key={item.title}>
              <BaseMenuButton
                onClick={() => {
                  setDrawerOpen(!isDrawerOpen)
                  setDrawerIndex(i)
                }}
              >
                {item.title}
              </BaseMenuButton>
            </li>
          )
        })}
      </ul>
      <MobileSublist
        items={drawerIndex >= 0 ? items[drawerIndex].items : []}
        featured={drawerIndex >= 0 ? items[drawerIndex].featured : undefined}
        title={drawerIndex >= 0 ? items[drawerIndex].title : ''}
        activePage={currentPage || ''}
        handleClose={handleClose}
      />
    </motion.div>
  )
}
