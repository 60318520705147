import { Link } from 'components/link/Link'
import { SocialIconList } from 'components/svg/Svg'

import s from './Social.module.scss'

export type SocialLinks = {
  facebook?: string
  instagram?: string
  youtube?: string
  linkedin?: string
}

export const getSocialIcons = (key: SocialIconList) => {
  if (!key) return null

  if (key === 'facebook') {
    return (
      <svg viewBox="0 0 26 26" fill="none">
        <path
          d="M25 7.66667V18.3333C25 22.0152 22.0152 25 18.3333 25H7.66666C3.98477 25 1 22.0152 1 18.3333V7.66667C1 3.98477 3.98477 1 7.66666 1H18.3333C22.0152 1 25 3.98477 25 7.66667Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.667 25.0001C11.667 21.0001 11.667 17.0001 11.667 13.0001C11.667 10.0834 12.3337 7.66675 17.0003 7.66675"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 14.3333H11.6667H17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
  if (key === 'instagram') {
    return (
      <svg viewBox="0 0 26 26" fill="none">
        <path
          d="M13.0003 18.3332C15.9458 18.3332 18.3336 15.9453 18.3336 12.9998C18.3336 10.0543 15.9458 7.6665 13.0003 7.6665C10.0548 7.6665 7.66699 10.0543 7.66699 12.9998C7.66699 15.9453 10.0548 18.3332 13.0003 18.3332Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 18.3333V7.66667C1 3.98477 3.98477 1 7.66666 1H18.3333C22.0152 1 25 3.98477 25 7.66667V18.3333C25 22.0152 22.0152 25 18.3333 25H7.66666C3.98477 25 1 22.0152 1 18.3333Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M20.333 5.68034L20.3463 5.66553"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
  if (key === 'linkedin') {
    return (
      <svg viewBox="0 0 26 26" fill="none">
        <path
          d="M25 7.66667V18.3333C25 22.0152 22.0152 25 18.3333 25H7.66667C3.98477 25 1 22.0152 1 18.3333V7.66667C1 3.98477 3.98477 1 7.66667 1H18.3333C22.0152 1 25 3.98477 25 7.66667Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33398 19.6666V14.9999V10.3333"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.667 19.6666V15.3333M11.667 15.3333V10.3333M11.667 15.3333C11.667 10.3333 19.667 10.3333 19.667 15.3333V19.6666"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33398 6.34689L6.34779 6.33154"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
  if (key === 'youtube') {
    return (
      <svg viewBox="0 0 28 28" fill="none">
        <path
          d="M16.6002 14.0001L12.0502 17.662V10.3381L16.6002 14.0001Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 15.2955V12.7045C1 7.40319 1 4.75256 2.17714 3.04706C3.35429 1.34157 5.20753 1.26774 8.914 1.12009C10.6703 1.05013 12.4644 1 14 1C15.5356 1 17.3297 1.05013 19.086 1.12009C22.7924 1.26774 24.6457 1.34157 25.8228 3.04706C27 4.75256 27 7.40319 27 12.7045V15.2955C27 20.5967 27 23.2474 25.8228 24.9528C24.6457 26.6583 22.7925 26.7321 19.0861 26.8799C17.3297 26.9498 15.5356 27 14 27C12.4644 27 10.6703 26.9498 8.91388 26.8799C5.20746 26.7321 3.35426 26.6583 2.17712 24.9528C1 23.2474 1 20.5967 1 15.2955Z"
          stroke="currentColor"
          strokeWidth="1.8"
        />
      </svg>
    )
  }
}

export const Social = ({ items }: { items?: SocialLinks }) => {
  const hasItems = items && Object.keys(items).length > 0

  if (!items || !hasItems) {
    return null
  }

  return (
    <ul className={s.social}>
      {Object.entries(items).map(([key, value]) =>
        key && value ? (
          <li className={s.social__item} key={key}>
            <Link to={value} title={key}>
              {getSocialIcons(key as SocialIconList)}
            </Link>
          </li>
        ) : null
      )}
    </ul>
  )
}
