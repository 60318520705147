/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames/bind'
import { UIContext } from 'context/ui'

import { useKeyDown } from 'hooks/useKeyDown'

import s from './Modal.module.scss'
import { IconClose } from 'components/icon/Icon'

const c = classNames.bind(s)

type ModalDefaults = {
  children: React.ReactElement
  className?: string
  dismissed?: boolean // additional flag showing modal, ie. local storage value
}

type ManualModal = {
  active: boolean
  onClose: () => void
  timeout?: never
} & ModalDefaults

type TimeoutModal = {
  timeout: number
  active?: never
  onClose?: () => void
} & ModalDefaults

export type ModalProps = ManualModal | TimeoutModal

export const Portal = ({ children }: { children: React.ReactElement }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (isMounted) {
    const domEl = document.getElementById('modal-container')

    return domEl ? createPortal(children, domEl) : null
  } else {
    return null
  }
}

export const Modal = ({
  children,
  active,
  dismissed,
  onClose,
  timeout,
  className,
}: ModalProps) => {
  const { setUIState } = useContext(UIContext)
  const modalInnerRef = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)

  const [localStateActive, setLocalStateActive] = useState(false)

  // copy over active state to local
  useEffect(() => {
    if (active !== undefined) {
      setLocalStateActive(active)
    }
  }, [active])

  useEffect(() => {
    if (dismissed) {
      setLocalStateActive(false)
    }
  }, [dismissed])

  useEffect(() => {
    setUIState({ canScroll: !localStateActive })
  }, [localStateActive, setUIState])

  // focus content on active
  useEffect(() => {
    if (localStateActive) {
      modalInnerRef.current?.focus()
    }
  }, [localStateActive])

  // generic close
  const handleClose = useCallback(() => {
    onClose?.()
    setLocalStateActive(false)
  }, [onClose])

  // escape to close
  useKeyDown('Escape', handleClose)

  // if timeout prop is set, set timeout to open modal
  useEffect(() => {
    if (!timeout || dismissed) {
      return
    }

    const timer = setTimeout(() => setLocalStateActive(true), timeout)

    return () => {
      clearTimeout(timer)
    }
  }, [timeout, dismissed])

  return (
    <Portal>
      <CSSTransition
        nodeRef={nodeRef}
        in={localStateActive}
        classNames={{ ...s }}
        addEndListener={(done) => {
          nodeRef.current?.addEventListener('transitionend', done, false)
        }}
        unmountOnExit
      >
        <div
          ref={nodeRef}
          className={c(s.modal, className)}
          role="dialog"
          aria-modal="true"
          aria-hidden={!active}
        >
          <button
            className={s.modal__backdrop}
            onClick={handleClose}
            tabIndex={-1}
          />
          <div className={s.modal__inner} tabIndex={0} ref={modalInnerRef}>
            <div className={s.modal__header}>
              <button onClick={handleClose} tabIndex={0} aria-label="close">
                <IconClose />
              </button>
            </div>
            {children}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  )
}
