import { BannerType } from 'components/banner/Banner'
import { Maybe } from 'prismic-types'

interface LayoutBanner {
  showBanner: Maybe<boolean> | undefined
  bannerType: BannerType | undefined
  bannerTitle: Maybe<string> | undefined
  bannerDescription: Maybe<string> | undefined
}

const sessionStorageKey = 'bannerData'

export const getStoredBannerData = (): Partial<
  LayoutBanner & { dismissed: boolean }
> => {
  return JSON.parse(sessionStorage.getItem(sessionStorageKey) || '{}')
}

export const setStoredBannerData = (
  data: Partial<LayoutBanner & { dismissed: boolean }>
) => {
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))
}

export const clearStoredBannerData = () => {
  sessionStorage.removeItem(sessionStorageKey)
}
