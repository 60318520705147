import { PrismicImage } from 'prismic/types/image';

import { MediaPosition } from 'types/ui';
import { getFileExt } from 'utils/getFileExt';

import { Picture, PictureFormats } from './Picture';

export type PrismicPictureProps = {
  image: PrismicImage;
  className?: string;
  width?: number;
  height?: number;
  lazy?: boolean;
  compress?: boolean;
  multiplier?: number;
  isMobile?: boolean;
  imagePosition?: MediaPosition;
};

function getComputedWidth(
  image: Exclude<PrismicImage, null>,
  preferedWidth?: number,
  isMobile = false
) {
  // sane default image width
  let w = 600;

  if (preferedWidth) {
    if (image.dimensions?.width && preferedWidth > image.dimensions.width) {
      w = image.dimensions.width;
    } else {
      if (preferedWidth > 1400 && isMobile) {
        w = 1400;
      } else {
        w = preferedWidth;
      }
    }
  }

  return w;
}

function getComputedHeight(image: Exclude<PrismicImage, null>, w: number) {
  return Math.round(
    w * ((image.dimensions?.height ?? 0) / (image.dimensions?.width ?? 0))
  );
}

// returns prismic image URL from prismic image
export function prismicPictureUrl({
  image,
  width,
  height,
  multiplier = 1,
  isMobile = false,
  compress = true,
}: PrismicPictureProps) {
  if (!image) {
    return null;
  }

  const w = getComputedWidth(image, width, isMobile);

  // if height is defined, and if height is less than image dimensions it should be used.
  // otherwise computed based on width
  let h: number;

  if (height && height <= (image.dimensions?.height ?? 0)) {
    h = height;
  } else {
    h = getComputedHeight(image, w);
  }

  const url = image.url;

  if (!url) {
    return null;
  }

  const src = new URL(url);
  const p = src.searchParams;
  const extension = getFileExt(src.pathname) ?? 'jpg';

  if (multiplier > 1) {
    // if multiplied width is greater than original image source, dont scale up
    if (w * multiplier > (image.dimensions?.width ?? 0)) {
      return null;
    }

    // dont serve images over 3000px
    if (w * multiplier > 3000) {
      p.set('w', '3000');
      p.set('h', getComputedHeight(image, 3000).toString());
    }
  } else {
    p.set('w', (w * multiplier).toString());
    p.set('h', (h * multiplier).toString());
  }

  p.set('auto', compress ? 'format, compress' : 'format');

  const result = {
    src: src.href,
    extension,
    width: w,
    height: h,
  };

  return result;
}

// returns <Picture component />
export function PrismicPicture({
  image,
  className,
  width,
  height,
  lazy,
  compress = true,
  imagePosition,
}: PrismicPictureProps) {
  if (!image || !image.url) {
    return null;
  }

  const x1 = prismicPictureUrl({ image, width, height, compress });
  const x2 = prismicPictureUrl({ image, width, height, multiplier: 2, compress });
  const mobile = prismicPictureUrl({ image, width, height, compress, isMobile: true });

  const formats: PictureFormats = {};
  if (x1 && x2) {
    formats[x1.extension] = {
      x1: x1.src,
      x2: x2.src,
      mobile: mobile?.src,
    };
  }

  return (
    <Picture
      src={x1?.src ?? ''}
      width={x1?.width}
      height={x1?.height}
      alt={image.alt ?? ''}
      formats={formats}
      className={className}
      lazy={lazy}
      imagePosition={imagePosition}
    />
  );
}

// <Picture> component + width & height as separate values
export function wrappedPrismicPicture(
  image: PrismicImage,
  w?: number,
  h?: number,
  imagePosition?: MediaPosition
) {
  if (!image) {
    return null;
  }

  let width = 0;

  if (w) {
    if (image.dimensions?.width && w > image.dimensions.width) {
      width = image.dimensions.width;
    } else {
      width = w;
    }
  }

  const height =
    h ??
    Math.round(
      width * ((image.dimensions?.height ?? 0) / (image.dimensions?.width ?? 0))
    );

  return {
    component: PrismicPicture({ image, width, height, imagePosition }),
    width,
    height,
  };
}
