/*
 * Visual grid reference, viewed in dev environment only.
 * overlays content with grid columns / grid container for reference
 */
import { useEffect, useState } from 'react'
import s from './GridOverlay.module.scss'

export const GridOverlay = ({
  handleToggle,
  visible,
}: {
  handleToggle: () => void
  visible: boolean
}) => {
  const [showGrid, setShowGrid] = useState(false)
  useEffect(() => {
    setShowGrid(visible)
  }, [visible])

  return (
    <div className={s.grid}>
      <div suppressHydrationWarning className={s.grid__container}>
        {showGrid && <div className={s.grid__visual} />}
      </div>
      <button
        className={s.grid__button}
        onClick={handleToggle}
        title="toggle grid"
      />
    </div>
  )
}
