import { motion, type Variants } from 'framer-motion'

import { useKeyDown } from 'hooks/useKeyDown'
import { useUiState } from 'hooks/useUiState'

import s from './MegaMenu.module.scss'
import { MegaMenuGroup } from './MegaMenuGroup'
import { MegaMenuFeatured } from './MegaMenuFeatured'
import { FeaturedProps } from 'components/header/Header'
import { NavBarPropsColumn } from 'components/header/Navbar'
import { useEffect, useRef } from 'react'

type MegaMenuProps = {
  items: NavBarPropsColumn[]
  featured: FeaturedProps
  onMouseEnterMegaMenu: () => void
  onMouseLeaveMegaMenu: () => void
}

const menu = {
  closed: {
    display: 'none',
    opacity: 0,
    y: '-5%',
    transition: {
      when: 'afterChildren',
      type: 'spring',
      duration: 0.4,
      display: { when: 'afterChildren' },
    },
  },
  open: {
    display: 'block',
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.4,
      delayChildren: 0.05,
      staggerChildren: 0.05,
    },
  },
} satisfies Variants

export const MegaMenu = ({ items, featured, onMouseEnterMegaMenu, onMouseLeaveMegaMenu }: MegaMenuProps) => {
  const { uiState, setUIState } = useUiState()
  const menuRef = useRef(null)

  const handleClose = () => {
    if (uiState.isNavOpen) {
      setUIState({ isNavOpen: false })
    }
  }

  useKeyDown('Escape', handleClose)

  useEffect(() => {
    const clickity = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const isNav = target.closest('nav')
      const ref = menuRef as React.RefObject<HTMLElement>
      if (!isNav && ref.current && !ref.current.contains(e.target as Node)) {
        setUIState({ isNavOpen: false })
      }
    }
    if (uiState.isNavOpen) {
      document.addEventListener('click', clickity)
    } else {
      document.removeEventListener('click', clickity)
    }

    return () => {
      document.removeEventListener('click', clickity)
    }
  }, [uiState.isNavOpen, setUIState])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { image, ...requiredProps } = featured
  const isValidFeatured = !Object.values(requiredProps).some(
    (value) => value == null
  )

  return (
    <motion.section
      ref={menuRef}
      animate={uiState.isNavOpen ? 'open' : 'closed'}
      variants={menu}
      className={s.megamenu}
      exit={menu.closed}
      initial={menu.closed}
      onMouseEnter={onMouseEnterMegaMenu} 
      onMouseLeave={onMouseLeaveMegaMenu}
    >
      <div className={s.megamenu__container}>
        <div className={s.groups}>
          {items?.map((columns, i) => {
            return (
              <div className={s.column} key={`megamenu-column-${i}`}>
                {columns.map(({ groupLabel, items }) => {
                  return (
                    <div style={{ marginBottom: '32px' }} key={groupLabel}>
                      <MegaMenuGroup title={groupLabel} links={items} />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        {isValidFeatured ? <MegaMenuFeatured featured={featured} /> : null}
      </div>
    </motion.section>
  )
}
