/* eslint-disable @typescript-eslint/no-explicit-any */
import { MegaMenu } from 'components/mega-menu/MegaMenu'
import { FocusEvent, MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavBarPropsColumn, Navbar } from './Navbar'
import { Maybe, Navigation } from 'prismic-types'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { useUiState } from 'hooks/useUiState'
import { BaseButton } from 'components/menu-button/MenuButton'
import { useResize } from 'hooks/useResize'
import { Link } from 'components/link/Link'
import { useRouter } from 'next/navigation'
import { usePathname } from 'next/navigation'
import { MobileNavbar } from './MobileNavbar'
import { NavButton } from './NavButton'
import { Logo, MyPagesIcon } from 'components/icon/Icon'
import styles from './Header.module.scss'
import LanguageSwitcher from './LanguageSwitcher'
import { SiteLocale } from 'utils/i18n'
import { FormattedMessage, useIntl } from 'react-intl'
import { linkResolver } from 'prismic/linkResolver'

const StyledHeaderContainer = styled.header<{
  $condensed?: boolean
  $navOpen?: boolean
  translateY: number
}>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: var(--z-index-header, 1);
  padding: 0;
  transform: translateY(${(props) => props.translateY}px);

  transition: translate 0.2s, padding 0.3s;

  @media (min-width: 1080px) {
    padding: 16px ${(props) => (props.$condensed ? '48px' : '16px')};
  }

  @media (max-width: 1079px) {
    transition: background 0.3s;
    .nav-open & {
      background: rgba(255, 255, 255, 0.8);
    }
  }
`

const StyledMegaMenuContainer = styled.div<{ $extraTop: number }>`
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 48px;
  top: calc(108px + ${(props) => props.$extraTop || 0}px);
`

type HeaderProps = {
  navigation: (Maybe<Navigation> | undefined)[]
  locale: SiteLocale
  bannerHeight: number
}

export type FeaturedProps = {
  heading: Navigation['featured_heading']
  subheading: Navigation['featured_subheading']
  image: Navigation['featured_image']
  link: Navigation['featured_link']
}

export type MappedNavigation = {
  title: string
  ordering: number
  featured: FeaturedProps
  items: NavBarPropsColumn[]
  childrenPaths: string[]
  uniquePaths: string[]
}

export const Header = ({ navigation, locale, bannerHeight }: HeaderProps) => {
  const { scrollY } = useScroll()
  const router = useRouter()
  const { uiState, setUIState } = useUiState()
  const { isMobile } = useResize()
  const [menuItems, setMenuItems] = useState([] as NavBarPropsColumn[])
  const [featured, setFeatured] = useState({} as FeaturedProps)
  const [mappedNavigation, setMappedNavigation] = useState([] as any)
  const [isScrolled, setIsScrolled] = useState(false)
  const [menuExtraTop, setMenuExtraTop] = useState(16)
  const [translateY, setTranslateY] = useState(bannerHeight)
  const HOVER_OFF_DELAY = 200
  const currentPath = usePathname()
  const { formatMessage } = useIntl()

  useEffect(() => {
    setTranslateY(bannerHeight)
  }, [bannerHeight])

  useEffect(() => {
    setUIState({ currentPage: currentPath })
  }, [setUIState, currentPath])

  const toggleMenu = (event: MouseEvent | FocusEvent, index: number) => {
    const { isNavOpen, megaMenuIndex } = uiState

    if (isNavOpen && megaMenuIndex === index) {
      event.stopPropagation()
    } else {
      setMenuItems(mappedNavigation[index]?.items)
      setUIState({ isNavOpen: true, megaMenuIndex: index })
      setFeatured(mappedNavigation[index]?.featured)
    }
  }

  let menuCloseTimeout: ReturnType<typeof setTimeout> | null = null

  const startMegaMenuCloseTimeout = () => {
    menuCloseTimeout = setTimeout(() => {
      setUIState({ isNavOpen: false, megaMenuIndex: undefined })
    }, HOVER_OFF_DELAY)
  }

  const cancelMegaMenuCloseTimeout = () => {
    clearTimeout(menuCloseTimeout as unknown as number)
    menuCloseTimeout = null
  }

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const translateValue = Math.max(bannerHeight - latest, 0) // From bannerheight px to 0px

    setTranslateY(translateValue)
    if (isScrolled && latest === 0) {
      setIsScrolled(false)
      setMenuExtraTop(16)
    } else if (!isScrolled && latest > 0) {
      setIsScrolled(true)
      setMenuExtraTop(0)
    }
  })

  useEffect(() => {
    const uniqueNavPaths: string[] = []
    const mapped: MappedNavigation[] | unknown = navigation
      ?.map((navItem, index) => {
        const navPaths: string[] = []
        const uniPaths: string[] = []
        const columns =
          navItem &&
          navItem.slices?.reduce((acc, { variation }, i) => {
            const columnId =
              variation?.primary?.column !== null
                ? Number(variation?.primary?.column)
                : i

            if (!Array.isArray(acc[columnId])) {
              acc[columnId] = []
            }
            acc[columnId].push({
              groupLabel: variation?.primary?.label ?? '',
              items: variation?.items?.map(({ label, link }) => {
                const resolvedLink = linkResolver(link)
                navPaths.push(resolvedLink)

                // List of unique paths per navigation group because the same page can be present in multiple groups
                if (!uniqueNavPaths.includes(resolvedLink)) {
                  uniPaths.push(resolvedLink)
                  uniqueNavPaths.push(resolvedLink)
                }
                return {
                  title: label ?? '',
                  link: link ?? '',
                }
              }),
            } as any)

            return acc
          }, [] as NavBarPropsColumn[])

        return {
          title: navItem?.label ?? '',
          ordering: navItem?.ordering ?? index,
          featured: {
            heading: navItem?.featured_heading,
            subheading: navItem?.featured_subheading,
            image: navItem?.featured_image,
            link: navItem?.featured_link,
          },
          items: Object.values(columns || {}),
          childrenPaths: navPaths, // All subpages in this group
          uniquePaths: uniPaths, // List of first instance of each subpage
        }
      })
      .sort((a, b) => a.ordering - b.ordering)

    setMappedNavigation(mapped as any)
  }, [navigation])

  return (
    <StyledHeaderContainer $condensed={isScrolled} translateY={translateY}>
      <div
        className={styles.header}
        onMouseLeave={startMegaMenuCloseTimeout}
        onMouseEnter={cancelMegaMenuCloseTimeout}
      >
        <div>
          <button
            onClick={() => (!uiState.isNavOpen ? router.push('/') : null)}
            className={styles.header__logoContainer}
            aria-label={formatMessage({
              id: 'home',
              defaultMessage: 'Fara á forsíðu',
            })}
          >
            <Logo />
          </button>
          <div>
            {!isMobile && (
              <Navbar toplevel={mappedNavigation} toggleMenu={toggleMenu} />
            )}
          </div>
          <div className={styles.header__actionsContainer}>
            <LanguageSwitcher isMobile={isMobile} locale={locale} />

            <Link to="https://minarsidur.orkusalan.is">
              <BaseButton variant="clear">
                <MyPagesIcon />
                {!isMobile && (
                  <FormattedMessage
                    id="my_sites"
                    defaultMessage="Mínar síður"
                  />
                )}
              </BaseButton>
            </Link>

            {!!isMobile && (
              <>
                <NavButton />
              </>
            )}
          </div>
        </div>
        {!!isMobile && <MobileNavbar items={mappedNavigation} />}
      </div>
      {!isMobile && (
        <StyledMegaMenuContainer $extraTop={menuExtraTop}>
          {/* <AnimatePresence> */}
          <MegaMenu
            items={menuItems}
            featured={featured}
            onMouseEnterMegaMenu={cancelMegaMenuCloseTimeout}
            onMouseLeaveMegaMenu={startMegaMenuCloseTimeout}
          />
          {/* </AnimatePresence> */}
        </StyledMegaMenuContainer>
      )}
    </StyledHeaderContainer>
  )
}
