import { linkResolver } from 'prismic/linkResolver'
import { Link } from '../link/Link'
import s from './MegaMenu.module.scss'
import { FeaturedProps } from 'components/header/Header'
import styled from 'styled-components'

const BackgroundLink = styled(Link)<{ background?: string }>`
  ${(props) =>
    props.background
      ? ` @media (min-width: 1080px) {background: url(${props.background}) no-repeat center center; background-size: cover;}`
      : ``}
`

export const MegaMenuFeatured = ({ featured }: { featured: FeaturedProps }) => {
  const url = linkResolver(featured.link)
  return (
    <BackgroundLink
      to={url}
      className={s.featured}
      background={featured.image?.url}
    >
      <h3 className={s.featured__title}>{featured.heading}</h3>
      <span>{featured.subheading}</span>
    </BackgroundLink>
  )
}
