import Head from 'next/head';

const tagManagerId = process.env.NEXT_PUBLIC_TAG_MANAGER;
const liveChatLicense = process.env.NEXT_PUBLIC_LIVE_CHAT_LICENSE;

export const TrackingMeta = () => {
  return (
    <Head>
      {tagManagerId && (
        // eslint-disable-next-line @next/next/next-script-for-ga
        <script
          id="gtm-init"
          key="gtm-init"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${tagManagerId}');`,
          }}
        />
      )}

      {liveChatLicense && (
        <script
          type="text/plain"
          data-consent="analytics"
          key="livechat"
          defer
          id="livechat-script"
          dangerouslySetInnerHTML={{
            __html: `
              window.__lc = window.__lc || {};
              window.__lc.license = ${liveChatLicense};
              window.__lc.asyncInit = true;
              ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)};
              var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){
              i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},
              get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");
              return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){
              var n=t.createElement("script");
              n.async=!0,n.type="text/javascript",
              n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};
              !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
            `,
          }}
        />
      )}
    </Head>
  );
};
