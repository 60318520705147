import { _Linkable } from 'prismic-types'
import { MenuButton } from '../menu-button/MenuButton'
import { FeaturedProps } from './Header'
import s from './Navbar.module.scss'
import { useUiState } from 'hooks/useUiState'
import { FocusEvent, MouseEvent } from 'react'

export type NavBarPropsChild = {
  groupLabel: string
  column: number
  items: { title: string; link: _Linkable }[]
}

export type NavBarPropsColumn = NavBarPropsChild[]
export type NavbarProps = {
  toplevel: {
    title: string
    ordering: number
    url: string
    children?: NavBarPropsColumn[]
    featured: FeaturedProps
    childrenPaths: string[]
    uniquePaths: string[]
  }[]
  toggleMenu: (event: MouseEvent | FocusEvent, i: number) => void
}

export const Navbar = ({ toplevel, toggleMenu }: NavbarProps) => {
  const { uiState } = useUiState()
  const { megaMenuIndex, isNavOpen, currentPage, isLoading, whereIcameFrom } =
    uiState

  const hasCurrentPage = (paths: string[]) =>
    !!currentPage && paths.includes(currentPage)

  return (
    <nav className={s.navbar}>
      {toplevel?.map(({ title, childrenPaths, uniquePaths }, i) => {
        return (
          <MenuButton
            text={title}
            isOpen={!!isNavOpen && megaMenuIndex === i}
            isCurrent={
              (!isNavOpen && // Keep indicator while loading
                whereIcameFrom === i &&
                isLoading) ||
              (!isNavOpen &&
                whereIcameFrom === i &&
                hasCurrentPage(childrenPaths)) || // Look into where you came from if group contains current page
              (!isNavOpen &&
                whereIcameFrom === undefined &&
                hasCurrentPage(uniquePaths)) // If unknown where you came from, find the first instance of the current page
            }
            key={`navbar-top-level-${i}`}
            toggleMenu={(event) => {
              toggleMenu(event, i)
            }}
          />
        )
      })}
    </nav>
  )
}
