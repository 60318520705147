import { Children } from 'react'

import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout'

import s from './Footer.module.scss'

type FooterProps = {
  children: React.ReactNode
  bottom?: React.ReactElement
  contact?: React.ReactElement
}

export const Footer = ({ children, bottom, contact }: FooterProps) => {
  return (
    <footer className={s.footer}>
      <GridLayout className={s.footer__grid}>
        {children &&
          Children.map(children, (item, i) => {
            return (
              <GridLayoutItem
                columns={{ tablet: 6, desktop: i === 0 ? 3 : 2 }}
                key={i}
                className={s.footer__item}
              >
                {item}
              </GridLayoutItem>
            )
          })}
        <GridLayoutItem columns={{ tablet: 6, desktop: 5 }}>
          {contact}
        </GridLayoutItem>
      </GridLayout>
      <GridLayout className={s.footer__grid}>
        <GridLayoutItem
          columns={{ tablet: 12, desktop: 12 }}
          className={s.footer__logo}
        >
          {bottom}
        </GridLayoutItem>
      </GridLayout>
    </footer>
  )
}
