/* eslint-disable @next/next/no-html-link-for-pages */
import s from './PreviewNotice.module.scss';

export function PreviewNotice() {
  return (
    <div className={s.previewNotice}>
      <a href="/api/exit-preview">
        You are viewing this page in preview mode. Click to exit.
      </a>
    </div>
  );
}
