import { MediaPosition } from 'types/ui'
import { Breakpoints } from 'utils/cleverCropping'

export type PictureFormats = {
  [key: string]: sizes
}

type sizes = {
  x1?: string
  x2?: string // retina
  wide?: string // use for art direction only
  desktop?: string // use for art direction only
  tablet?: string // use for art direction only
  mobile?: string // use for art direction only
  objectPosition?: { x: number; y: number }
}

export type PictureProps = {
  src: string // the default fallback image
  width?: number
  height?: number
  alt?: string
  lazy?: boolean
  className?: string
  draggable?: boolean
  formats?: PictureFormats
  imagePosition?: MediaPosition
  breakpoints?: Breakpoints
}

export const Picture = ({
  src,
  formats,
  width,
  height,
  alt,
  lazy = true,
  draggable,
  className,
  imagePosition,
  breakpoints,
}: PictureProps) => {
  let imageStyle = {} as React.CSSProperties

  if (imagePosition) {
    Object.entries(imagePosition).forEach(([k, v]) => {
      const entry = { [`--object-${k}`]: v }
      imageStyle = { ...imageStyle, ...entry }
    })
  }

  const renderImage = (
    <img
      className={className}
      src={src}
      width={width}
      height={height}
      alt={alt ?? ''}
      loading={lazy ? 'lazy' : 'eager'}
      draggable={draggable}
      style={imageStyle}
    />
  )

  if (!formats) {
    return renderImage
  }

  const formatArray: Array<{
    formatType: string
    srcSet: string
    media?: string
  }> = []

  const setFormats = () => {
    Object.entries(formats).forEach(([k, v]) => {
      if (v.mobile && breakpoints) {
        formatArray.push({
          formatType: k,
          srcSet: `${v.mobile}`,
          media: breakpoints.mobileMedia,
        })
      } else if (v.mobile) {
        formatArray.push({
          formatType: k,
          srcSet: `${v.mobile}`,
          media: '(max-width: 719px)',
        })
      }

      if (v.tablet) {
        formatArray.push({
          formatType: k,
          srcSet: `${v.tablet}`,
          media: breakpoints?.tabletMedia ?? undefined,
        })
      }

      if (v.desktop) {
        formatArray.push({
          formatType: k,
          srcSet: `${v.desktop}`,
          media: breakpoints?.desktopMedia ?? undefined,
        })
      }

      if (v.wide && breakpoints) {
        formatArray.push({
          formatType: k,
          srcSet: `${v.wide}`,
          media: breakpoints.wideMedia ?? undefined,
        })
      } else if (v.wide) {
        formatArray.push({
          formatType: k,
          srcSet: `${v.wide}`,
          media: '(min-width: 720px)',
        })
      }

      if (!breakpoints) {
        formatArray.push({ formatType: k, srcSet: `${v.x1} 1x` })
      }
    })

    return formatArray.map(
      (
        item: { formatType: string; srcSet: string; media?: string },
        i: number
      ) => {
        return (
          <source
            key={i}
            srcSet={item.srcSet}
            media={item.media ?? undefined}
          />
        )
      }
    )
  }

  return (
    <picture>
      {setFormats()}
      {renderImage}
    </picture>
  )
}
