import {
  FilledContentRelationshipField,
  FilledLinkToMediaField,
  FilledLinkToWebField,
  LinkType,
} from '@prismicio/types'
import {
  PrismicLinkWithMeta,
  PrismicLinkWithPotentialTitle,
} from 'prismic/types/link'
import { _ExternalLink, Meta } from 'prismic-types'

import { asText } from 'components/rich-text/RichText'

import { localeToLink } from '../utils/i18n'

import { PrismicRichText } from './types/richtext'

/** Type of link when coming throught the Prismic preview feature */
export type PreviewLink = {
  id?: string
  uid?: string
  url?: string | null
  type?: string
  href?: string
  tags?: Array<string>
  first_publication_date?: string
  last_publication_date?: string
  slugs?: Array<string>
  linked_documents?: Array<unknown>
  lang?: string
  alternate_languages?: Array<unknown>
  data?: unknown
}

/** The types we can link resolve, add any project specific types here */
export type LinkResolvable =
  | (FilledLinkToWebField & { _linkType?: string })
  | (FilledLinkToMediaField & { _linkType?: string })
  | (FilledContentRelationshipField & { _linkType?: string })
  | PrismicLinkWithMeta
  | null

/** Possible values for normalized Prismic links */
export type LinkType = 'Web' | 'Document' | 'Media' | 'Any'

/** Normalize different representation of links in Prismic into one type */
export type NormalizedLink = {
  /** Prismic returns this as either link_type or _linkType */
  linkType?: LinkType | string
  uid?: string
  /** This is the document type, e.g. "frontpage", "page", "article" */
  type?: string
  url?: string
  locale?: string
  name?: string
}

/** Result of resolving a link along with its title */
export type ResolvedPrismicLinkWithTtitle = {
  link: string
  title: string
}

/**
 * When adding links via the Prismi UI, it's possible to paste in relative links (e.g. `/my-page`).
 * In those cases the UI will append `https://` in front, so we end up with `https:///my-page`.
 *
 * @param url Possible url from the Prismic UI
 * @returns Url with protocol stripped
 */
function fixRelative(url?: string): string {
  if (!url) {
    return '/'
  }

  const lowered = url.toLocaleLowerCase()

  let stripped = url

  // Prismic UI will allow OR add https:// for relative links in their UI
  if (lowered.startsWith('https:///')) {
    stripped = url.substring('https://'.length)
  }

  if (lowered.startsWith('http:///')) {
    stripped = url.substring('http://'.length)
  }

  try {
    const relUrl = new URL(url)
    const { host } = relUrl

    if (host === 'orkusalan.is' || host === 'www.orkusalan.is') {
      const relativeUrl = relUrl.href.replace(relUrl.origin, '')
      return relativeUrl
    }
  } catch (error) {
    return stripped
  }

  return stripped
}

const ensureSingleDashes = (url: string) => url.replace(/\/+/g, '/')

/**
 * Resolves a link to content based on its type.
 *
 * @param type Name of the content type in Prismic
 * @param uid UID of content
 * @param locale Locale of content
 * @returns Resolved link to the content based on input
 */
export function linkResolverByType(
  type?: string,
  uid?: string,
  locale?: string,
  parent?: string
): string {
  const _parent = parent && parent !== '/' ? `/${parent}/` : ''
  const localeString = localeToLink(locale)

  switch (type) {
    case 'frontpage':
    case 'layout':
      return localeString
    case 'page':
      return ensureSingleDashes(`${localeString}${_parent}${uid}`)
    case 'form':
      return `${localeString}form/${uid}`
    case 'news':
      return `${localeString}frett/${uid}`
    case 'education':
      return ensureSingleDashes(`${localeString}stud${_parent}${uid}`)
    case 'power_station':
      return `${localeString}virkjun/${uid}`
    default:
  }

  if (uid) {
    return `${localeString}${uid}`
  }

  return localeString
}

/**
 * Resolves a link to content based on its meta object.
 *
 * @param meta Meta object of content from Prismic
 * @returns Resolved link to the content based on its meta object
 */
export function linkResolverByMeta(meta?: Meta, parent?: string): string {
  const uid = meta?.uid ?? ''
  const type = (meta?.type ?? '').toLowerCase()

  return linkResolverByType(type, uid, meta?.lang, parent)
}

/**
 * When linking between content there are two possible ways Prismic represents it:
 * - As link in RichText content
 * - As link in "Linked Content" UI element
 * This handles both types.
 *
 * @param link Normalized link to resolve
 * @returns Resolved link to the content based on its link object
 */
export function linkResolverByLink(link: NormalizedLink): string {
  if (!link.linkType) {
    return '/'
  }

  if (['Document', 'Link.document'].indexOf(link.linkType) >= 0) {
    return linkResolverByType(link.type, link.uid, link.locale)
  }

  // Possible values for link_type
  if (
    link.url &&
    (['Link.media', 'Link.web', 'Link.file', 'Link.image'].indexOf(
      link.linkType
    ) ||
      ['Web', 'File', 'Image', 'Media'].indexOf(link.linkType) >= 0)
  ) {
    if (
      (link.linkType === 'Media' ||
        link.linkType === 'File' ||
        link.linkType === 'Link.media' ||
        link.linkType === 'Link.file') &&
      /\.pdf$/.test(link.url)
    ) {
      return `/api/get-pdf?id=${link.url.replace(/.+\/([^/]+)$/, '$1')}${
        link.name ? '&name=' + link.name : ''
      }`
    }

    return fixRelative(link.url)
  }

  return '/'
}

function linkResolveParent(doc?: LinkResolvable) {
  if (doc && '_meta' in doc) {
    return linkResolverByMeta(doc._meta)
  }

  return '/'
}

/**
 * Checks the shape of the document to link resolve to and creates a link to it.
 *
 * @param doc Prismic document OR string to resolve a link to
 * @returns Link to document
 */
export function linkResolver(
  doc?: LinkResolvable | string,
  parentPath?: string
): string {
  if (!doc) {
    return '/'
  }

  if (typeof doc === 'string') {
    try {
      const url = new URL(doc)
      const { host } = url

      if (host.includes('orkusalan.is')) {
        const relativeUrl = url.href.replace(url.origin, '')
        return relativeUrl
      }
    } catch (error) {
      return doc
    }

    return doc
  }

  const parent =
    'parent' in doc
      ? linkResolveParent(doc.parent as LinkResolvable)
      : undefined

  if ('_meta' in doc) {
    return linkResolverByMeta(doc._meta, parent ?? parentPath)
  }

  // Preview and content relationship links
  if ('link_type' in doc && 'uid' in doc) {
    return linkResolverByLink({
      linkType: doc._linkType || doc.link_type,
      uid: doc.uid,
      type: doc.type,
      url: doc.url,
      locale: doc.lang,
    })
  }

  if ('_linkType' in doc) {
    const link = doc as _ExternalLink
    return linkResolverByLink({
      linkType: link._linkType ?? undefined,
      url: link.url,
    })
  }

  // Document links
  if ('type' in doc) {
    return linkResolverByType(doc.type, doc.uid, doc.lang)
  }

  // Media and Web links
  if ('url' in doc) {
    return linkResolverByLink({
      linkType: doc._linkType || doc.link_type,
      url: doc.url,
    })
  }

  const locale = 'lang' in doc ? localeToLink(doc.lang) : '/'

  if ('uid' in doc) {
    return `${locale}${doc.uid}`
  }

  return locale
}

export function resolvePrismicLinkWithTitle(
  potentialLink?: unknown,
  potentialLinkText?: unknown
): ResolvedPrismicLinkWithTtitle | null {
  if (!potentialLink) {
    return null
  }

  // linkResolver tries to resolve safely (but we should maybe double check that as a TODO)
  const link = linkResolver(potentialLink as LinkResolvable)

  // The link might come with a title
  const linkTitle: string | null = (
    potentialLink as PrismicLinkWithPotentialTitle
  ).title
    ? asText((potentialLink as PrismicLinkWithPotentialTitle).title)
    : null

  // but if we're passed a title that is not the empty string, use that
  const passedTitle = asText(potentialLinkText as PrismicRichText)

  return {
    link,
    title: passedTitle || linkTitle || link,
  }
}
