import { Maybe } from 'prismic-types'
import s from './Banner.module.scss'
import {
  IconClose,
  IconError,
  IconWarning,
  IconSuccess,
  IconInformation,
} from 'components/icon/Icon'
import { RichTextField } from '@prismicio/types'
import { RichText } from 'components/rich-text/RichText'

export type BannerType = 'Information' | 'Success' | 'Warning' | 'Error'

const getStyleAndIcon = (bannerType?: BannerType) => {
  if (!bannerType) {
    return
  }
  if (bannerType === 'Information') {
    return {
      style: {
        backgroundColor: '#E0E4FA',
        color: '#151E51',
      },
      icon: <IconInformation />,
    }
  }
  if (bannerType === 'Error') {
    return {
      style: {
        backgroundColor: '#FEF0F3',
        color: '#620410',
      },
      icon: <IconError />,
    }
  }
  if (bannerType === 'Success') {
    return {
      style: {
        backgroundColor: '#F3FBF3',
        color: '#285C34',
      },
      icon: <IconSuccess />,
    }
  }
  if (bannerType === 'Warning') {
    return {
      style: {
        backgroundColor: '#FDF7E5',
        color: '#724204',
      },
      icon: <IconWarning />,
    }
  }
}

type Props = {
  bannerRef: React.RefObject<HTMLDivElement>
  onClick: () => void
  title?: Maybe<string>
  description?: RichTextField
  type?: BannerType
}

export const Banner = ({
  title,
  description,
  type,
  onClick,
  bannerRef,
}: Props) => (
  <div
    ref={bannerRef}
    className={s.banner}
    style={getStyleAndIcon(type)?.style}
  >
    <div className={s.container}>
      <div className={s.info}>
        <div className={s.heading}>
          <div className={s.icon}>{getStyleAndIcon(type)?.icon}</div>
          {title ? <p className={s.title}>{title}</p> : null}
        </div>

        <div className={s.dot}>·</div>
        {description && (
          <RichText className={s.description}>{description}</RichText>
        )}
      </div>

      <button
        onClick={onClick}
        tabIndex={0}
        aria-label="close"
        className={s.button}
      >
        <div>
          <IconClose />
        </div>
      </button>
    </div>
  </div>
)
