import { MouseEvent, useState } from 'react'
import s from './MenuButton.module.scss'
import { useUiState } from 'hooks/useUiState'

type MenuButtonProps = {
  text: string
  toggleMenu: (event: MouseEvent) => void
  isCurrent: boolean
  isOpen: boolean
}

export const MenuButton = ({
  text,
  toggleMenu,
  isCurrent = false,
  isOpen = false,
}: MenuButtonProps) => {
  const { uiState } = useUiState()
  const [onHoverTimeout, setOnHoverTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null)
  const HOVER_DELAY = 150

  const handleClick = (event: MouseEvent) => {
    toggleMenu(event)
  }

  const handleFocus = (event: any) => {
    toggleMenu(event)
  }

  const startHoverTimeout = (
    event: MouseEvent,
    isNavOpen: boolean | undefined
  ) => {
    if (isNavOpen) {
      setOnHoverTimeout(
        setTimeout(() => {
          handleClick(event)
          setOnHoverTimeout(null)
        }, HOVER_DELAY)
      )
    } else {
      handleClick(event)
    }
  }

  const cancelHoverTimeout = (
    onHoverTimeout: ReturnType<typeof setTimeout> | null
  ) => {
    if (onHoverTimeout !== null) {
      clearTimeout(onHoverTimeout as unknown as number)
      setOnHoverTimeout(null)
    }
  }

  return (
    <button
      onClick={handleClick}
      onMouseEnter={(event) => startHoverTimeout(event, uiState.isNavOpen)}
      onMouseLeave={() => cancelHoverTimeout(onHoverTimeout)}
      onFocus={handleFocus}
      className={`${s.menuButton} ${isOpen ? s.isOpen : ''} ${
        isCurrent ? s.isCurrent : ''
      }`}
    >
      {text}
    </button>
  )
}

type BaseButtonProps = {
  children: React.ReactNode
  variant?: 'primary' | 'clear'
  handleOnClick?: () => void
}

export const BaseButton = ({
  children,
  variant = 'primary',
  handleOnClick,
}: BaseButtonProps) => {
  return (
    <button className={s[`baseButton-${variant}`]} onClick={handleOnClick}>
      {children}
    </button>
  )
}

export const BaseMenuButton = (props: any) => {
  return <button {...props}>{props.children}</button>
}
