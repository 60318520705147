import { Social, SocialLinks } from 'components/social/Social'

import { LayoutSocial_Links } from '../prismic-types'

import { linkResolver } from './linkResolver'

export function toSocial(items: Array<LayoutSocial_Links>) {
  const socialItems: SocialLinks = {}

  items.forEach((item) => {
    if (!item.link) {
      return
    }
    const link = linkResolver(item.link)

    if (item.type === 'facebook') {
      socialItems.facebook = link
    }

    if (item.type === 'linkedin') {
      socialItems.linkedin = link
    }

    if (item.type === 'youtube') {
      socialItems.youtube = link
    }

    if (item.type === 'instagram') {
      socialItems.instagram = link
    }
  })

  return <Social items={socialItems} />
}
